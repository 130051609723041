import React from 'react'

import Layout from '../components/Layout';
import { MyLocationEnum } from '../components/Layout/utils/enum'
import VideoRoom from '../components/ConferenceRoom/components/VideoRoom';
import SEO, { MetaOG } from "../components/Seo"
import { useStaticQuery, graphql } from 'gatsby';

interface Props {
  location: Location
}
export default ({ location }: Props) => {
  const seo_og: MetaOG = {
    title: "Bein útsending",
    description: "Bein útsending",
  }

  const data = useStaticQuery(
    graphql`
      query {
        Background: file(relativePath: { eq: "jólafjarhátíð-bg-beinútsending.jpeg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1800) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        beint {
          slido
          video
        }
      }
    `
  )

  return (
    <Layout location={location} myLocation={MyLocationEnum.Live}>
      <SEO
        title="Bein útsending"
        description="Bein útsending"
        og={seo_og}
      />
      <VideoRoom 
        videoLink={data.beint.video}
        slidoLink={data.beint.slido}
        backgroundImage={data.Background.childImageSharp.fluid} 
      />
    </Layout>
  )
}